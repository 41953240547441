import React from "react"
import { graphql } from "gatsby"

import Posts from "../components/Posts"
import Layout, { Section } from "../components/Layout"
import PageImage from "../components/PageUnsplashImage"
import { HTMLContent } from "../components/Content"
import { Link } from "../components/Link"

const ContactLink = ({ contactPrefix }) => (
  <div className="content">
    {contactPrefix} <Link to="/contact?formVariation=research">Entra em contato com a gente!</Link>
  </div>
)

export const PostsPageTemplate = ({
  contactPrefix,
  content,
  description,
  image,
  imageCredit,
  title,
  posts,
  tags,
}) => {
  return (
    <div>
      <PageImage src={image} credit={imageCredit}>
        <h1 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen">
          {title}
        </h1>
      </PageImage>

      <Section>
        <HTMLContent content={description} />

        <ContactLink contactPrefix={contactPrefix} />

        <Posts posts={posts} tags={tags}>
          <HTMLContent className="content" content={content} />
        </Posts>
      </Section>
    </div>
  )
}

const PostsPage = ({ data }) => {
  const page = data.markdownRemark
  const { edges: posts, group } = data.allMarkdownRemark

  return (
    <Layout>
      <PostsPageTemplate
        title={page.frontmatter.title}
        image={page.frontmatter.image}
        imageCredit={page.frontmatter.imageCredit}
        description={page.frontmatter.description}
        contactPrefix={page.frontmatter.contactPrefix}
        content={page.html}
        posts={posts}
        tags={group}
      />
    </Layout>
  )
}

export default PostsPage

export const query = graphql`
  query PostsPage($id: String!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "post-page" } } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            authors
            file
            date
            featuredpost
            youtubeURL
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        contactPrefix
        imageCredit {
          author
          href
        }
        image
      }
    }
  }
`
